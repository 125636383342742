export function autosuggestions() {
	const suggestionsElements = document.querySelectorAll(".js-auto-suggestions");
	if (suggestionsElements.length) {

		const testSuggestions = ["Apple", "Banana", "Cherry", "Date", "Elderberry", "Fig", "Grape"];
		const useTestData = false; // Set to `true` in the test environment, always set to false before committing

		suggestionsElements.forEach((suggestionsElement) => {
			const htmlElement = document.querySelector("html");
			suggestionsElement.addEventListener("input", () => {
				const inputValue = suggestionsElement.value;
				if (inputValue.length > 1) {
					if (useTestData) {
						// Use the test array instead of fetching from the API
						const suggestionsMarkup = generateSuggestionsMarkup(testSuggestions, inputValue);
						addSuggestionsMarkup(suggestionsElement, suggestionsMarkup);

					} else {
						const pageId = suggestionsElement.getAttribute("data-search-pageid");
						const useraffle = suggestionsElement.getAttribute("data-useraffle");
						const lang = htmlElement.getAttribute("lang");
						// Define the API URL
						const apiUrl =
							"/umbraco/api/search/GetTypeAhead?searchTerm=" +
							inputValue +
							"&pageId=" +
							pageId +
							"&lang=" +
							lang +
							"&useRaffle=" +
							useraffle;
						fetch(apiUrl, {
							method: "GET",
							headers: {
								"Content-Type": "application/json",
							},
						})
							.then((response) => response.json())
							.then((data) => {
								// Handle the response data
								if (Array.isArray(data)) {
									if (data.length) {
										const suggestionsMarkup = generateSuggestionsMarkup(
											data,
											inputValue,
										);
										addSuggestionsMarkup(suggestionsElement, suggestionsMarkup);
									} else {
										removeSuggestions(suggestionsElement);
									}
								} else {
									removeSuggestions(suggestionsElement);
								}
							})
							.catch((error) => {
								// Handle any errors
								console.error("Error:", error);
							});
					}


				}
				if (inputValue.length == 0) {
					removeSuggestions(suggestionsElement);
				}
			});
			// suggestionsElement.addEventListener("blur", () => {
			// 	setTimeout(() => {
			// 		removeSuggestions(suggestionsElement);
			// 	}, 200); // Timeout to allow click on suggestion
			// });
			suggestionsElement.addEventListener("click", (event) => {
				event.preventDefault();
			});

			suggestionsElement.addEventListener("keydown", (event) => {
				const suggestionsContainer = suggestionsElement.parentElement.parentElement.querySelector(
					".suggestions--autocomplete ul",
				);
				if (suggestionsContainer) {
					const items = suggestionsContainer.querySelectorAll("li");
					let currentIndex = Array.from(items).findIndex((item) =>
						item.classList.contains("highlighted"),
					);
					if (event.key === "ArrowDown") {
						event.preventDefault();
						if (currentIndex < items.length - 1) {
							currentIndex++;
						} else {
							currentIndex = 0;
						}
						highlightItem(items, currentIndex);
					} else if (event.key === "ArrowUp") {
						event.preventDefault();
						if (currentIndex > 0) {
							currentIndex--;
						} else {
							currentIndex = items.length - 1;
						}
						highlightItem(items, currentIndex);
					} else if (event.key === "Enter") {
						event.preventDefault();
						if (currentIndex > -1) {
							suggestionsElement.value = items[currentIndex].innerText;
							removeSuggestions(suggestionsElement);

							const closestForm = suggestionsElement.closest("form");
							if (closestForm) {
								closestForm.submit();
							}
						} else {
							const closestForm = suggestionsElement.closest("form");
							if (closestForm) {
								closestForm.submit();
							}
						}
					}
				}
			});
		});
	}

	function generateSuggestionsMarkup(suggestions, searchTerm) {
		let markup = '<div class="suggestions--autocomplete active"><ul>';
		const searchTermRegex = new RegExp(`(${searchTerm})`, "gi");
		suggestions.forEach((suggestion) => {
			const highlightedSuggestion = suggestion.replace(
				searchTermRegex,
				"<b>$1</b>",
			);
			markup += `<li><button>${highlightedSuggestion}</button></li>`;
		});
		markup += "</ul></div>";
		return markup;
	}

	function addSuggestionsMarkup(inputElement, markup) {
		const formElement = inputElement.closest("form");
		if (formElement) {
			// Remove existing suggestions if present
			const existingSuggestions = formElement.querySelector(
				".suggestions--autocomplete",
			);
			if (existingSuggestions) {
				existingSuggestions.remove();
			}

			// Insert the new suggestions
			formElement.insertAdjacentHTML("beforeend", markup);

			// Add click event listener to each suggestion
			const suggestionButtons = formElement.querySelectorAll(".suggestions--autocomplete button");
			suggestionButtons.forEach(button => {
				button.addEventListener("click", (event) => {
					event.preventDefault();
					inputElement.value = button.innerText;
					removeSuggestions(inputElement);
					const closestForm = inputElement.closest("form");
					if (closestForm) {
						closestForm.submit();
					}
				});
			})
		}
	}
	function removeSuggestions(inputElement) {
		const formElement = inputElement.closest("form");
		if (formElement) {
			const existingSuggestions = formElement.querySelector(
				".suggestions--autocomplete",
			);
			if (existingSuggestions) {
				existingSuggestions.remove();
			}
		}
	}
	function highlightItem(items, index) {
		items.forEach((item, idx) => {
			if (idx === index) {
				item.classList.add("highlighted");
				item.scrollIntoView({ block: "nearest" });
			} else {
				item.classList.remove("highlighted");
			}
		});
	}
}

export default autosuggestions;
