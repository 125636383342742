export function headerMegamenuFocusTrap() {
    if(document.querySelector('.header__megamenu-extended')) {
        const navbarToggler = document.querySelector('.navbar-toggler');
        const navbarCollapse = document.querySelector('.navbar-collapse');
        const dropdownMegamenu = document.querySelectorAll('.dropdown-menu__megamenu') ? document.querySelectorAll('.dropdown-menu__megamenu') : false;

        if(navbarCollapse){
            navbarCollapse.addEventListener('focusout', (e) => {
                if(!navbarCollapse.contains(e.relatedTarget)){
                    navbarToggler.focus();
                }
            })  
        }
        if(dropdownMegamenu){
            dropdownMegamenu.forEach(element => {
                element.addEventListener('focusout', (e) => {
                    if(!element.contains(e.relatedTarget)){
                        element.querySelector('.nav-back-link').focus();
                    }
                })                  
            });
        }
    }
}

export default headerMegamenuFocusTrap;