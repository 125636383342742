/**
 * Three column carousel - carousel on tablet down
 * https://owlcarousel2.github.io/
 */

import jquery from "jquery";

// eslint-disable-next-line no-unused-vars
// import owl from '../../../../node_modules/owl.carousel/dist/owl.carousel.min'

export function threeColumnCarousel() {
  jQuery(function () {
    var carousels = jQuery('.three-column-carousel');

    if (carousels.length < 1) {
      return;
    }

    jQuery(document).ready(function () {
      if (jQuery(window).width() <= 991) {
        carousels.each(function() {
          startCarousel(jQuery(this));
        });
      } else {
        carousels.each(function() {
          jQuery(this).addClass('off');
        });
      }
    });

    let resizeTimeout;

    jQuery(window).resize(function () {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(function () {
        if (jQuery(window).width() <= 991) {
          carousels.each(function() {
            startCarousel(jQuery(this));
          });
        } else {
          carousels.each(function() {
            stopCarousel(jQuery(this));
          });
        }
      }, 500); // Adjust the delay as needed
    });

    function startCarousel(carousel) {
      if (carousel.hasClass('owl-loaded')) return; // Check if already initialized

      // var $dataStagepadding = carousel.data("stagepadding");

      // if ($dataStagepadding >= 1) {
      //   carousel.closest(".container").addClass("container--wide__important");
      // }

      carousel.closest(".container").addClass("container--wide__important");

      carousel.owlCarousel({
        stagePadding: 40,
        items: 3,
        nav: false,
        dots: true,
        pullDrag: true,
        touchDrag: true,
        mouseDrag: true,
        responsiveClass: true,
        center: true,
        responsive: {
          0: {
            items: 1,
            dots: true,
            stagePadding: 40
          },
          600: {
            items: 2,
            dots: true,
            stagePadding: 40 * 2
          },
          991: {
            items: 2
          }
        }
      });

      carousel.find('.owl-dots').each(function () {
        var $this = jQuery(this);
        var $ul = jQuery('<ul class="owl-dots"></ul>');
        $this.children().each(function(index) {
          var $dot = jQuery(this);
          var $li = jQuery('<li></li>');
          var $button = jQuery('<button class="owl-dot" aria-label="Slide ' + (index + 1) + '"></button>');
          
          if ($dot.hasClass('active')) {
            $button.addClass('active');
          }

          $button.on('click', function() {
            carousel.trigger('to.owl.carousel', [index, 300]);
          });

          $button.append('<span></span>');
          $li.append($button);
          $ul.append($li);
        });
        $this.replaceWith($ul);
      });

      carousel.on('changed.owl.carousel', function(event) {
        var index = event.item.index;
        carousel.find('.owl-dot').removeClass('active');
        carousel.find('.owl-dot').eq(index).addClass('active');
      });

      carousel.find('.owl-item').on('focusin', function () {
        var desiredItem = jQuery(this);
        var desiredItemIndex = carousel.find('.owl-item').index(desiredItem);
        carousel.trigger('to.owl.carousel', [desiredItemIndex, 300, true]);
      });
    }

    function stopCarousel(carousel) {
      carousel.trigger('destroy.owl.carousel');
      carousel.addClass('off');
    }

  });
}

export default threeColumnCarousel;
