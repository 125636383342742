/*
 ** Form elements
 ** JS help for custom form elements
 */

export function formElements() {

	// Select-checkboxes
  jQuery('.select-checkboxes.dropdown .dropdown-toggle').on('click', function (event) {
    console.log('click dropdown-toggle');
    var $dropdown = jQuery(this).parent('.dropdown');
    var $menu = jQuery(this).siblings('.dropdown-menu');
    var isExpanded = jQuery(this).attr('aria-expanded') === 'true';

    // Toggle dropdown visibility
    if (isExpanded) {
      jQuery(this).attr('aria-expanded', 'false');
      $dropdown.removeClass('show');
      $menu.removeClass('show');
    } else {
      jQuery(this).attr('aria-expanded', 'true');
      $dropdown.addClass('show');
      $menu.addClass('show');
      
      // Check if dropdown needs to open downward on desktop
      if (!isMobileView()) {
        // Adjust position if it's too close to the top
        var menuOffset = $menu.offset().top;
        var viewportHeight = jQuery(window).height();
        var dropdownHeight = $menu.outerHeight();
        if (menuOffset + dropdownHeight > viewportHeight) {
          // $menu.css('top', 'auto'); // Reset top positioning
          // $menu.css('bottom', '0'); // Align the dropdown at the bottom
        } else {
          // $menu.css('bottom', ''); // Reset bottom positioning if enough space
        }
      }
    }

    // Prevent the event from bubbling
    event.stopPropagation();
  });

  // Close dropdown when clicking outside
  jQuery(document).on('click', function (event) {
    if (!jQuery(event.target).closest('.select-checkboxes.dropdown').length) {
      jQuery('.select-checkboxes.dropdown').each(function () {
        jQuery(this).removeClass('show');
        jQuery(this).find('.dropdown-menu').removeClass('show');
        jQuery(this).find('.dropdown-toggle').attr('aria-expanded', 'false');
      });
    }
  });

  // Mobile view check function
  function isMobileView() {
      return window.innerWidth <= 768; // You can adjust the breakpoint as needed
  }

	jQuery('.textarea.with-counterJS textarea').on('input', function () {
		var maxlength = jQuery(this).attr('maxlength');
		var currentLength = jQuery(this).val().length;
		let minLength = jQuery(this).attr('minlength');

		// disable button and show warning for min length
if (minLength !== undefined) {
	if (currentLength >= minLength) {
		jQuery(this).parent('.textarea.with-counterJS').find(jQuery('.invalid-feedback')).css('visibility', 'hidden');
		jQuery(this).parents('.feedback-banner__message').find(jQuery('.send-response')).removeAttr('disabled')
	} else {
		jQuery(this).parent('.textarea.with-counterJS').find(jQuery('.invalid-feedback')).css('visibility', 'visible');
		jQuery(this).parents('.feedback-banner__message').find(jQuery('.send-response')).attr('disabled', 'true')
	}
}


		if (currentLength >= maxlength) {
			return
		}

		var textareaContainer = jQuery(this).parent('.textarea.with-counterJS');
		var currentLenghtContainer = textareaContainer.find(
			'.textarea-counter__currentlength p'
		);
		currentLenghtContainer.text(currentLength);
	});
}

export default formElements;
