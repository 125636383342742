import { debounce } from 'lodash-es';

export function tabpanelReworked() {
  const ACTIVE_CLASS = "active";
  const IS_OPEN_CLASS = "is-open"; // New class for the container
  const TAB_PANEL_SELECTOR = ".tab-panel--container";
  const TAB_CONTROL_SELECTOR = ".tab-control";
  const DESKTOP_PANEL_SELECTOR = ".tab-panel--desktop";
  const MOBILE_PANEL_SELECTOR = ".tab-panel--mobile";

  if (!document.querySelector(TAB_PANEL_SELECTOR)) return;

  // Helper: Get Active Tab from URL
  function getActiveTabFromUrl(id) {
    const url = new URL(window.location.href);
    const fragment = url.hash.slice(1).split("?")[0]; // Extract fragment before '?'
    const queryParams = new URLSearchParams(url.search);

    if (fragment === id) {
      const activeTab = queryParams.get("activeTab");
      return activeTab !== null ? parseInt(activeTab, 10) : null;
    }
    return null;
  }

  // Helper: Set Active State for Tabs
  function setActiveTab(tabPanel, index, isDesktop, allowToggle = true) {
    const controls = tabPanel.querySelectorAll(TAB_CONTROL_SELECTOR);
    const desktopPanels = tabPanel.querySelectorAll(DESKTOP_PANEL_SELECTOR);
    const mobilePanels = tabPanel.querySelectorAll(MOBILE_PANEL_SELECTOR);

    const isAlreadyActive = controls[index]?.classList.contains(ACTIVE_CLASS);

    // Allow toggling if the tab is already active
    if (allowToggle && isAlreadyActive) {
      controls[index].classList.remove(ACTIVE_CLASS);
      controls[index].setAttribute("aria-expanded", "false");
      if (isDesktop) {
        desktopPanels[index]?.classList.remove(ACTIVE_CLASS);
      } else {
        mobilePanels[index]?.classList.remove(ACTIVE_CLASS);
      }

      // Check if no tabs are active and remove the `is-open` class from the container
      // const hasActiveTabs = Array.from(controls).some((control) => control.classList.contains(ACTIVE_CLASS));
      // tabPanel.classList.toggle(IS_OPEN_CLASS, hasActiveTabs);

      return;
    }

    // Loop through all tabs to apply the active class
    controls.forEach((control, idx) => {
      const isActive = idx === index && (!allowToggle || !isAlreadyActive);
      control.classList.toggle(ACTIVE_CLASS, isActive);
      control.setAttribute("aria-expanded", isActive ? "true" : "false");
    });

    // Update the panels based on whether it's desktop or mobile
    if (isDesktop) {
      desktopPanels.forEach((panel, idx) => panel.classList.toggle(ACTIVE_CLASS, idx === index && (!allowToggle || !isAlreadyActive)));
    } else {
      mobilePanels.forEach((panel, idx) => panel.classList.toggle(ACTIVE_CLASS, idx === index && (!allowToggle || !isAlreadyActive)));
    }

    // Check if at least one tab is active and add/remove the `is-open` class
    // const hasActiveTabs = Array.from(controls).some((control) => control.classList.contains(ACTIVE_CLASS));
    // tabPanel.classList.toggle(IS_OPEN_CLASS, hasActiveTabs);
  }

  // Initialize Tabs
  document.querySelectorAll(TAB_PANEL_SELECTOR).forEach((tabPanel) => {
    const id = tabPanel.id;
    const activeTabFromUrl = getActiveTabFromUrl(id); // Get activeTab from URL
    const controls = Array.from(tabPanel.querySelectorAll(TAB_CONTROL_SELECTOR));
    const isDesktop = window.innerWidth >= 992;

    let initialActiveTab = null;

    // If there's an activeTab in the URL, use that as the initial active tab
    if (activeTabFromUrl !== null && activeTabFromUrl < controls.length) {
      initialActiveTab = activeTabFromUrl;
    } else {
      // If no activeTab in the URL, check for the active class in the HTML
      const htmlActiveIndex = controls.findIndex((tab) => tab.classList.contains(ACTIVE_CLASS));

      // If the active class is found in the HTML, use that index
      if (htmlActiveIndex !== -1) {
        initialActiveTab = htmlActiveIndex;
      }
    }

    // If there's no activeTab defined in the URL or HTML, don't set any tab as active.
    if (initialActiveTab !== null) {
      setActiveTab(tabPanel, initialActiveTab, isDesktop, false);
    }

    // Add event listeners for tab changes
    controls.forEach((tab, index) => {
      tab.addEventListener("click", () => {
        const isDesktop = window.innerWidth >= 992;
        setActiveTab(tabPanel, index, isDesktop);

        if (id) {
          // Update the URL to reflect the active tab if a tab is active
          const url = new URL(window.location.href);
          const isTabActive = controls[index].classList.contains(ACTIVE_CLASS);

          if (isTabActive) {
            url.hash = `${id}`;  // Add the hash fragment to the URL
            url.searchParams.set("idTab", `${id}`); // Update the activeTab query parameter
            url.searchParams.set("activeTab", index); // Update the activeTab query parameter
          } else {
            url.hash = ""; // Clear the hash fragment
            url.searchParams.delete("idTab"); // Update the activeTab query parameter
            url.searchParams.delete("activeTab"); // Remove the activeTab query parameter
          }

          window.history.replaceState(null, "", url.toString()); // Replace history without reload
        }
      });
    });
  });

  // Handle Resize Events
  let windowSize = window.innerWidth;
  window.addEventListener(
    "resize",
    debounce(() => {
      const isDesktop = window.innerWidth >= 992;
      if ((isDesktop && windowSize < 992) || (!isDesktop && windowSize >= 992)) {
        document.querySelectorAll(TAB_PANEL_SELECTOR).forEach((tabPanel) => {
          const activeIndex = Array.from(
            tabPanel.querySelectorAll(TAB_CONTROL_SELECTOR)
          ).findIndex((tab) => tab.classList.contains(ACTIVE_CLASS));
          setActiveTab(tabPanel, activeIndex >= 0 ? activeIndex : null, isDesktop, false);
        });
        windowSize = window.innerWidth;
      }
    }, 200)
  );
}

export default tabpanelReworked;
