import hoverIntent from "../../../../node_modules/superfish/dist/js/hoverintent";
import Headroom from "headroom.js";
import superfish from "../../../../node_modules/superfish/dist/js/superfish.min";
import { set } from "lodash-es";

// Navbar
export function headerMegamenuExtended() {
  if (jQuery(".header__megamenu-extended").length < 0) {
    return;
  }

  if (jQuery("header").hasClass("header__megamenu-extended")) {
    // Variables
    const $body = jQuery("body");
    const $navbar = jQuery(".header__megamenu-extended");
    const $dropdown = jQuery(".header__megamenu-extended .nav-item.dropdown");
    const $dropdownSubmenu = jQuery(".header__megamenu-extended .dropdown-submenu__ul");
    const $navLink = jQuery(".header__megamenu-extended .nav-link");
    const $dropdownToggle = jQuery(".header__megamenu-extended .dropdown-toggle");
    const $dropdownMenu = jQuery(".header__megamenu-extended .dropdown-menu");
    const $searchbar = jQuery(".header__megamenu-extended__search-bar");
    const $searchbarToggle = jQuery(".navbar__search-toggle");
    const $navBackLink = jQuery(".header__megamenu-extended .nav-back-link");
    const showClass = "sfHover";
    const header = jQuery(".header__megamenu-extended");
    const $dropdownToggleTab = jQuery(".header__megamenu-extended .dropdown-toggle__tab");
    const $submenuDropdownToggle = jQuery(".header__megamenu-extended .dropdown-submenu__button");
    let $textOpen = $searchbarToggle.attr("data-textOpen");
    let $textClose = $searchbarToggle.attr("data-textClose");
    let isMenuOpen = false;
    let ignoreScroll = false;

    const bodyScrollLock = require("body-scroll-lock");
    const { disableBodyScroll, enableBodyScroll } = bodyScrollLock;

    // sets the top padding to the height of the header. Not in use right now
    const setPaddingMain = () => {
      const headerHeight = header.outerHeight();
      document.documentElement.style.setProperty("--header-height-lg", `${headerHeight}px`);
      header[0].style.position = "fixed";
    };

    const handleHoverIntent = (element) => {
      const listElements = document.querySelectorAll(".nav-item");
      listElements.forEach((el) => el.classList.remove("visible"));
      element.classList.add("visible");
    };

    const handleHoverOut = (element) => {
      element.classList.remove("visible");
    };

    const onScroll = () => {

      if(ignoreScroll) return
      const isMobile = jQuery(window).width() < 991;
      const headerElement = document.querySelector(".header__megamenu-extended");
      const height = headerElement.offsetHeight;
      const newScrollHeight = window.scrollY;

      if (newScrollHeight > height) {
        headerElement.style.position = "sticky";
        headerElement.style.top = "0px";
      } else {
        headerElement.style.position = "relative";
      }

      // Update position based on scroll direction (scroll up or down)
      if (newScrollHeight < window.oldScrollHeight && newScrollHeight > height) {
        // Scrolling up
        headerElement.style.position = "sticky";
        headerElement.style.top = "0px";
      } else if (newScrollHeight > window.oldScrollHeight && newScrollHeight > height && !isMenuOpen) {
        // Scrolling down
        headerElement.style.position = "sticky";
        headerElement.style.top = `-${height}px`;
      } else if (newScrollHeight < height) {
        headerElement.style.position = "sticky";
        headerElement.style.top = "0px";
      }

      window.oldScrollHeight = newScrollHeight;
    };

    const onLoad = () => {
      //Reset all menus on load and resize
      $navbar.removeClass("show-mobile-menu");
      $('.navbar-collapse').removeClass('show');
      $('.navbar-toggler-icon ').removeClass('open');
      $('.navbar-toggler').attr('aria-expanded', 'false');
      
      const resetDropdowns = () => {
        jQuery(".header__megamenu-extended .dropdown-menu__megamenu, .header__megamenu-extended .dropdown-submenu__button").removeClass("show");
        jQuery(".header__megamenu-extended .dropdown-menu__megamenu, .header__megamenu-extended .dropdown-submenu__ul").removeClass("show");
        jQuery(".dropdown-submenu__ul").slideUp();
      };
      //Hide dropdowns on load
      $dropdownMenu.removeClass("show"); 

      jQuery(".header__megamenu-extended .dropdown-menu__megamenu").removeClass('show');
      //resetDropdowns();
      // Position sticky if scrolled down page, otheerwise relative
      jQuery("main").css("padding-top", 0);
      const headerElement = document.querySelector(".header__megamenu-extended");
      const height = headerElement.offsetHeight;
      const scrollHeight = window.scrollY;
      if(scrollHeight > height){
        headerElement.style.position = 'sticky';
        headerElement.style.top = '0px';
      } else {
        headerElement.style.position = 'relative';
      }

      // Show submenu dropdowns for desktop view
      jQuery(".header__megamenu-extended .dropdown-submenu .dropdown-menu").show();
      jQuery("nav").removeClass("show-mobile-menu");

      $navBackLink.off("click").on("click", function () {
        const $this = jQuery(this);
        const $dropdown = $this.closest(".dropdown");
        $dropdown.find('.dropdown-toggle__tab').attr("aria-expanded", "false");
        
        setTimeout(() => {$dropdown.find('.dropdown-toggle__tab').get(0).focus();}, 10);
        console.log($dropdown.find('.dropdown-toggle__tab').get(0));

      });

      $dropdownToggleTab.off('click').on("click", function () {
        //Toggle current dropdown
        const $this = jQuery(this);
        $this.closest($dropdown).toggleClass("show");
        $this.attr("aria-expanded", $this.closest($dropdown).hasClass("show"));
        $this.closest($dropdown).find($dropdownMenu).toggleClass("show");
      });

      $submenuDropdownToggle.off('click').on("click", function () {
        const $this = jQuery(this);
        $this.toggleClass("show");
        $this.attr("aria-expanded", $this.hasClass("show"));
        $this.closest('.dropdown-submenu').find('.dropdown-submenu__ul').toggleClass("show");
      })
      
      $dropdownToggle.off('focus').on('focus', function() {
        const $this = jQuery(this);
        $this.closest($dropdown).removeClass("sfHover");
      })

      $dropdownMenu.off('focusout').on('focusout', function(event){

        // Only on desktop
        if((jQuery(window).width() > 990)){
          const $this = jQuery(this);
          const $navItem = $this.closest(".nav-item.dropdown");
  
          // Check if the newly focused element is outside the dropdown menu
          if (!$this.is(event.relatedTarget) && !$this.has(event.relatedTarget).length) {
            $this.removeClass("show");
            $navItem.removeClass("show");
            // Update the aria-expanded attributes
            $navItem.find(".dropdown-toggle").attr("aria-expanded", "false");
            $navItem.find(".dropdown-toggle__tab").attr("aria-expanded", "false");
          }
        }
      })

      // Mobile-specific behavior
      if (jQuery(window).width() < 991) {
          // Destroy Superfish on mobile to prevent hover issues
          jQuery(".header__megamenu-extended ul.navbar-nav__primary").superfish("destroy");

          jQuery(".navbar-toggler").off("click").on("click", function () {
            $body.toggleClass("burger-open").toggleClass("burger-closed");
            jQuery("nav").toggleClass("show-mobile-menu");
            jQuery(".navbar-toggler-icon").toggleClass("open");

            // Close search bar if it's open when burger menu is clicked
            $searchbar.slideUp();
            $searchbarToggle.attr("aria-expanded", "false").attr("aria-label", $textOpen);

            //Reset dropdowns
            if(!jQuery(".navbar-toggler-icon").hasClass("open")){
              resetDropdowns();
            }
          });

        // Ensure the search toggle works properly on initial load for mobile
        $searchbarToggle.off("click").on("click", function () {
          ignoreScroll = true;
          const $this = jQuery(this);
          const isOpen = $this.attr("aria-expanded") === "true";
          $searchbar.slideToggle();
          $this.attr("aria-expanded", !isOpen).attr("aria-label", isOpen ? $textOpen : $textClose);
          setTimeout(() => ignoreScroll = false, 800);
        });


        jQuery(document).off("click", ".nav-back-link").on("click", ".nav-back-link", function () {
          resetDropdowns();
        });
      } else {

        // Initialize Superfish on Desktop
        jQuery(".header__megamenu-extended ul.navbar-nav__primary").superfish({
          popUpSelector: "ul, .dropdown-menu__megamenu",
          speed: 200,
          delay: 500,
          disableHI: false,
          onInit: function() {
            jQuery(this).find("ul").removeAttr("style"); // Remove all inline styles after init
          },
          onBeforeShow: function() {
            jQuery(this).removeAttr("style"); // Ensure inline styles are not re-added when showing
          },
          onHide: function() {
            jQuery(this).removeAttr("style"); // Ensure inline styles are not re-added when hiding
          }
        });
        // Desktop-specific behavior: Initialize aria-expanded properly
        if ($searchbarToggle.length) {
          // Ensure aria-expanded is set correctly on desktop initial load
          const isSearchbarVisible = $searchbar.is(":visible");
          $searchbarToggle.attr("aria-expanded", isSearchbarVisible ? "true" : "false");
        }

        // Handle the click for opening/closing the search bar on desktop
        $searchbarToggle.off("click").on("click", function () {
          ignoreScroll = true; //prevent menu from disappearing when opening search bar
          const $this = jQuery(this);
          const isOpen = $this.attr("aria-expanded") === "true"; // Check if it was already opened
          $searchbar.slideToggle(); // Toggle the visibility of the search bar
          $this.attr("aria-expanded", !isOpen); // Update the aria-expanded state
          $this.attr("aria-label", isOpen ? $textOpen : $textClose); // Update the aria-label
          setTimeout(() => ignoreScroll = false, 800); //reinstate scroll after opening search bar
        });
      }
    };

    // Trigger the onLoad function on initial load and on resize
    jQuery(window).on("load resize", onLoad);

    // Bind scroll event to update header position
    window.addEventListener("scroll", onScroll);
  }
}

export default headerMegamenuExtended;
