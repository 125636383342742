import ratioCheckerPrefersWhite from "./ratioCheckerPrefersWhite";
import { throttle } from "lodash-es";

export function header() {

	const headerIsFixed = document.getElementsByClassName("fixed");
	const isTransparentHeader = document.getElementsByClassName(
		"header--transparent"
	);
	const backgroundColorProperty = "background-color";
	const noBackgroundColor = "rgba(0, 0, 0, 0)";

	const getElementStyle = (element, styleProperty) => {
		return window
			.getComputedStyle(element)
			.getPropertyValue(styleProperty)
			.toString();
	};

	const setDocumentColor = (target, color) => {
		root.style.setProperty(target, color);
	};

	const setTextColorWhite = () => {
		setDocumentColor("--changing-text-color", "white");
		setDocumentColor("--cta-changing-text-color", "black");
		document.getElementById("inverse-logo-id").style.display = "block";
		document.getElementById("logo-id").style.display = "none";
	};
	
	const setTextColorBlack = () => {
		setDocumentColor("--changing-text-color", "black");
		setDocumentColor("--cta-changing-text-color", "white");
		document.getElementById("inverse-logo-id").style.display = "none";
		document.getElementById("logo-id").style.display = "block";
	};

	let root = document.documentElement;

	const initialSettings = (
		sections,
		navbarListItems,
		navbarBackground,
		headerImage,
		bodyBackgroundColor,
		headerBackgroundColor
	) => {
		const main = document.getElementsByTagName("main")[0];
		const firstSection = sections[0] || null;
		/*
		 * First section scenarios:
		 * - Hero and banners with image
		 * - Banner
		 * - Section with no background color
		 * - Section with background color
		 * - Article pages (white body)
		 * */
		if (firstSection === null) {
			// Fallback behavior when there's no first section
			document.getElementById("inverse-logo-id").style.display = "block"; // Ensure the logo is displayed
			navbarBackground.style.backgroundColor = bodyBackgroundColor; // Fallback navbar color
			headerBackgroundColor.style.display = "block"; // Display header background color
			setDocumentColor("--navbar-bg-color", bodyBackgroundColor);
			ratioCheckerPrefersWhite(bodyBackgroundColor)
				? setTextColorWhite()
				: setTextColorBlack();
			return; // Exit after applying fallback settings
		}

		else if (
			(firstSection.querySelector("[class^='hero']") && !Array.from(firstSection.querySelector("[class^='hero']").classList).some(className => className.startsWith('bg-'))) ||
			firstSection.querySelector(".banner__image") ||
			firstSection.querySelector(".media-box--full-width") ||
			firstSection.querySelector(".image")
		) {
			headerBackgroundColor.style.display = "none";
			firstSection.classList.add("hero--transparent-header");

			// Set the right colors
			setDocumentColor("--changing-text-color", "white");
			setDocumentColor("--cta-changing-text-color", "black");
			setDocumentColor("--navbar-bg-color", noBackgroundColor);
			document.getElementById("inverse-logo-id").style.display = "block";

			/*increase height on small heros
			 * to ensure that the navigation
			 * bar does not conflict with
			 * hero content.
			 * If banner is absolute, no height is set */
			const hasBannerAbsolute = [...firstSection.classList].some(cls => cls.includes("banner--absolute"));
			if (
				((headerImage && headerImage.clientHeight < 500) && !hasBannerAbsolute) &&
				window.matchMedia("(min-width: 768px)").matches
			) {
				headerImage.style.height = "500px";
			}

			/*Remove Hero section from sections array
			 * to avoid activating scroll event before
			 * reaching the second section */
			sections.shift();

			return;

			/* if first section is hero with background color */
		} else if (firstSection?.querySelector("[class^='hero']") && Array.from(firstSection.querySelector("[class^='hero']").classList).some(className => className.startsWith('bg-'))){
			const hero = firstSection.querySelector("[class^='hero']")

			headerBackgroundColor.style.backgroundColor = getElementStyle(
				hero,
				backgroundColorProperty
				)
			headerBackgroundColor.style.display = "none";
			firstSection.classList.add("hero--transparent-header");
			if (
				(headerImage && headerImage.clientHeight < 500) &&
				window.matchMedia("(min-width: 768px)").matches
			) {
				headerImage.style.height = "500px";
			}
		} else if (firstSection.querySelector("[class^='banner']")) {

			/* We need to handle 'banner' differently because
			 * the background color is not set on the section */
			const banner = firstSection.querySelector("[class^='banner']");
			headerBackgroundColor.style.backgroundColor = getElementStyle(
				banner,
				backgroundColorProperty
			);
			headerBackgroundColor.style.display = "block";

		} else if (main && main.classList.contains("bg-main--white")) {
			setDocumentColor("--navbar-bg-color", "#fff");
			headerBackgroundColor.style.backgroundColor = "#fff";
			headerBackgroundColor.style.display = "block";
		} else {
			let hasNoBackgroundColor =
				getElementStyle(firstSection, backgroundColorProperty) ===
				noBackgroundColor;

			if (hasNoBackgroundColor) {
				setDocumentColor("--navbar-bg-color", bodyBackgroundColor);
				headerBackgroundColor.style.backgroundColor = bodyBackgroundColor;
			} else {
				setDocumentColor(
					"--navbar-bg-color",
					getElementStyle(firstSection, backgroundColorProperty)
				);
				headerBackgroundColor.style.backgroundColor = getElementStyle(
					firstSection,
					backgroundColorProperty
				);
			}
			headerBackgroundColor.style.display = "block";
		}

		/* Display header background color
		to save space for the menu
		at the top of the page */
		ratioCheckerPrefersWhite(
			getElementStyle(headerBackgroundColor, backgroundColorProperty)
		)
			? setTextColorWhite()
			: setTextColorBlack();
	};

	const handleScrollEvents = (
		headerImage,
		navbarListItems,
		navbarBackground,
		headerImageHeight,
		sections,
		bodyBackgroundColor,
		headerBackgroundColor,
		navbar
	) => {
		const mediaBox =
			headerImage && headerImage.querySelectorAll(".container")[0];

		const mediaBoxInnerColor =
			mediaBox && mediaBox.querySelector(".hero-inner");
		const mediaBoxOverlayColor =
			mediaBox && mediaBox.querySelector(".hero__multi-boxes__box__overlay");

		window.onscroll = throttle(() => {
			if (document.documentElement.scrollTop === 0) {
				console.log("scroll top 0");
				setDocumentColor(
					"--navbar-bg-color",
					getElementStyle(headerBackgroundColor, backgroundColorProperty)
				);
				ratioCheckerPrefersWhite(
					getElementStyle(headerBackgroundColor, backgroundColorProperty)
				)
					? setTextColorWhite()
					: setTextColorBlack();
			}

			resizeHeader(navbar);

			mediaBox &&
				onMultiBox(
					mediaBox,
					navbarListItems,
					mediaBoxInnerColor,
					mediaBoxOverlayColor
				);
			headerImage &&
				onHeaderImage(
					navbarBackground,
					navbarListItems,
					headerImageHeight,
					sections,
					bodyBackgroundColor
				);
			handleOnSections(
				sections,
				navbarListItems,
				bodyBackgroundColor,
				navbarBackground
			);
		}, 200);
	};
	
	//Burger toggle function
	if(isTransparentHeader[0] && isTransparentHeader[0].querySelector('.burger-button')){
		const openBurgerButton = isTransparentHeader[0].querySelector('.burger-button');
		const closeBurgerButton = isTransparentHeader[0].querySelector('.sidemenu__close');
		const sidemenu = isTransparentHeader[0].querySelector('.sidemenu');

		openBurgerButton.addEventListener('click', () => {
			sidemenu.classList.toggle('inactive');
			sidemenu.classList.toggle('active');
		})
		closeBurgerButton.addEventListener('click', () => {
			sidemenu.classList.toggle('inactive');
			sidemenu.classList.toggle('active');
		})
	}

	// Resizes header when it leaves the top
	function resizeHeader(navbar) {
		if (document.documentElement.scrollTop >= 50) {
			navbar && navbar.classList.add("scroll");
		} else {
			navbar && navbar.classList.remove("scroll");
		}
	}

	// Disable generic color on the hero Image.
	function onHeaderImage(navbarBackground, navbarListItems, imageHeight) {
		if (document.documentElement.scrollTop <= imageHeight) {
			console.log('onHeaderImage')
			setDocumentColor("--changing-text-color", "white");
			setDocumentColor("--cta-changing-text-color", "black");
			setDocumentColor("--navbar-bg-color", noBackgroundColor);
			document.getElementById("inverse-logo-id").style.display = "block";
			document.getElementById("logo-id").style.display = "none";
		}
	}

	/* on multiboxes when they stack under image on mobile view
	 * multiboxes has bg-color in two variations: overlay and bg-color. */
	function onMultiBox(multiBox, navbarListItems, inner, overlay) {
		if (
			document.documentElement.scrollTop >= multiBox.offsetTop &&
			document.documentElement.scrollTop <=
			multiBox.offsetTop + multiBox.clientHeight
		) {
			console.log('onMultiBox');
			if (window.matchMedia("(max-width: 767px)").matches) {
				if (inner) {
					setDocumentColor(
						"--navbar-bg-color",
						getElementStyle(inner, backgroundColorProperty)
					);
					ratioCheckerPrefersWhite(
						getElementStyle(inner, backgroundColorProperty)
					)
						? setTextColorWhite()
						: setTextColorBlack();
				}

				if (overlay) {
					setDocumentColor(
						"--navbar-bg-color",
						getElementStyle(overlay, backgroundColorProperty)
					);
					ratioCheckerPrefersWhite(
						getElementStyle(overlay, backgroundColorProperty)
					)
						? setTextColorWhite()
						: setTextColorBlack();
				}
			}
		}
	}

	function handleOnSections(sections, navbarListItems, bodyBackgroundColor) {
		for (let i = 0; i < sections.length; i++) {
			if (
				document.documentElement.scrollTop + 55 >= sections[i].offsetTop &&
				document.documentElement.scrollTop <=
					sections[i].offsetTop + sections[i].offsetHeight
			) {
				let sectionBgc = getElementStyle(sections[i], backgroundColorProperty);
				let main = document.getElementsByTagName("main")[0];

				if (main.classList.contains("bg-main--white")) {
					setDocumentColor("--navbar-bg-color", "rgb(255,255,255)");
					ratioCheckerPrefersWhite("rgb(255,255,255)")
						? setTextColorWhite()
						: setTextColorBlack();
					return;
				}

				if (sections[i].querySelector("[class^='banner']") !== null) {
					let banner = sections[i].querySelector("[class^='banner']");
					let bannerBacgroundColor = getElementStyle(
						banner,
						backgroundColorProperty
					);
					setDocumentColor("--navbar-bg-color", bannerBacgroundColor);
					ratioCheckerPrefersWhite(bannerBacgroundColor)
						? setTextColorWhite()
						: setTextColorBlack();
					return;
				} else if (sectionBgc === noBackgroundColor) {
					setDocumentColor("--navbar-bg-color", bodyBackgroundColor);
					ratioCheckerPrefersWhite(bodyBackgroundColor)
						? setTextColorWhite()
						: setTextColorBlack();
					return;
				} else {
					setDocumentColor("--navbar-bg-color", sectionBgc);
					ratioCheckerPrefersWhite(sectionBgc)
						? setTextColorWhite()
						: setTextColorBlack();
				}
			}
		}
	}

	const onMount = () => {
		const main = document.getElementsByTagName("main")[0];
		const sectionsHTMLcollection = main.getElementsByTagName("section") ? main.getElementsByTagName("section") : [];
		// array of all sections on page
		let sections = [].slice.call(sectionsHTMLcollection);
		const navbarBackground = document.querySelector(".nav--transparent");
		const navbarListItems = document.querySelector(".header-items");
		const body = document.getElementsByTagName("body")[0];
		const bodyBackgroundColor = getElementStyle(body, backgroundColorProperty);
		const headerImage = sections[0]?.querySelector("[data-fullwidthimage = true]") || null;
		const headerBackgroundColor = document.querySelector(
			".header__background-color"
		);
		const navbar = document.querySelector(".nav--transparent");

		initialSettings(
			sections,
			navbarListItems,
			navbarBackground,
			headerImage,
			bodyBackgroundColor,
			headerBackgroundColor
		);

		// select image height after initial settings.
		// const headerImageHeight =
		// 	headerImage &&
		// 	headerImage.querySelector(".media__container").clientHeight;
    // console.log(headerImageHeight);

    // select image height after initial settings.
    const headerImageHeight = headerImage ? headerImage.querySelector(".media__container").clientHeight : 0;

		// only run scroll events if menu is fixed
		if (headerIsFixed.length > 0) {
			handleScrollEvents(
				headerImage,
				navbarListItems,
				navbarBackground,
				headerImageHeight,
				sections,
				bodyBackgroundColor,
				headerBackgroundColor,
				navbar
			);
		}
	};

	isTransparentHeader.length > 0 ? onMount() : "";
}

export default header;
