/*
 * @title App
 * @description Application entry point
 */

// Modules
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import headerMegamenuExtended from '../modules/headers/header__megamenu-extended/header__megamenu-extended';
import headerMegamenuTabOrderMobile from '../modules/headers/header__megamenu-extended/tab-order-mobile';
import '../../node_modules/lazysizes/plugins/parent-fit/ls.parent-fit';
import login from './components/pageLogin';
import objectFitImages from './components/objectFitImages';
import blockMediaDownload from './components/block-media-download';
import headerIntranet from '../modules/intranet/header__intranet/header__intranet';
import footers from '../modules/footers/footers';
import watermark from '../modules/watermark/watermark';
import subscriptionModal from '../modules/modals/modal-subscription/modal-subscription';
import pagination from '../modules/pagination/pagination';
import carouselCards from '../modules/carousels/carousel__cards/carousel__cards';
import articleAnchorMenu from '../modules/anchor-links/article-anchor-menu/article-anchor-menu';
import accordion from '../modules/accordions/accordion';
import autosuggestions from '../modules/search-bars/autosuggestions.js';
import accordionMobile from '../modules/accordions/accordion--mobile/accordion--mobile';
import accordionDesktopDown from '../modules/accordions/accordion--desktop-down/accordion--desktop-down';
import infoboxLinksIcon from '../modules/info-boxes/info-box__links__icon/info-box__links__icon';
import threeColumnCarousel from '../modules/carousels/three-column-carousel/three-column-carousel';
import progress from '../modules/progressbar/progress';
import swiper from '../modules/carousels/swiper/swiper';
import heroMultiBox from '../modules/heros/hero__multi-box/hero__multi-box';
import video from '../modules/videos/video';
import videoDreambroker from '../modules/videos/video-dreambroker';
import videoYoutube from '../modules/videos/video-youtube';
import BorgerDk from '../modules/third-party/borgerDk/borgerDk';
import SBSYS from '../modules/third-party/sbsys/sbsysaccordion.js';
import smoothScroll from './components/smooth-scroll.js';
import pageLoader from './components/page-loader.js';
import cssVars from 'css-vars-ponyfill';
import tooltips from './components/tooltips';
import intextArrowLink from './components/intext-arrow-link';
import serviceMenu from '../modules/service-menu/service-menu';
import serviceMenuShareByEmail from '../modules/service-menu/service-menu__shareByEmail';
import dataTabel from '../modules/tabel/tabel';
import dataTabelScrollable from '../modules/tabel/tabel';
import formElements from './components/form-elements';
import sideMenu from '../modules/side-menu/side-menu';
import instagramFeed from '../modules/third-party/instagram-feed/instagram-feed';
import counterAnimation from '../modules/side-boxes/side-boxes__counter/side-boxes__counter';
import kultunaut from '../modules/third-party/kultunaut/kultunaut-card/kultunaut-card';
import kultunautPopularEvent from '../modules/side-boxes/side-boxes__most-popular/side-boxes__most-popular';
import modalPopup from '../modules/modals/modal-popup/modal-popup';
import tabListScrollable from '../modules/intranet/tab-list--scrollable/tab-list--scrollable';
import videoBackground from './components/video-backgrounds';
import cludoSearch from '../modules/third-party/cludo-search/cludo-search';
import imageGallery from '../modules/image/image-gallery/image-gallery';
import chat from '../modules/intranet/chat/chat';
import onShowMore from '../modules/fact-box/onShowMore';
import collapseBox from '../modules/fact-box/collapseBox';
import onShowMoreCard from '../modules/cards/card__comment/onShowMoreCard';
import card__comment from '../modules/cards/card__comment/card__comment';
import header from '../modules/headers/header__transparent-menu/scripts/header';
//import cardHoursStatus from '../modules/cards/card__hours/cardHoursStatus';
import { moveNavItems } from '../modules/headers/header__transparent-menu/scripts/moveNavItems';
import parallax from '../modules/creative/parallax/parallax';
import dropdownMdDown from '../modules/dropdowns/dropdown--md-down/dropdown--md-down';
import bannerHours from '../modules/banners/banner__hours/bannerHours';
import videoHTML from '../modules/videos/video-html';
import breadcrumb  from '../modules/breadcrumbs/breadcrumb/breadcrumb';
import headerTagged from "../modules/headers/header--tagged/headerTagged";
//import tabpanel from "../modules/tab-panels/tabpanel";
import headerLeftAligned from '../modules/headers/header__megamenu-left-aligned/headerLeftAligned';
import doubleLogoMenu from '../modules/headers/header__double-logo/doubleLogoMenu';
import collapse from '@alpinejs/collapse';
import leftAlignedMegamenu from '../modules/headers/header__megamenu-left-aligned/leftAlignedMegamenu';
import unsaveButton from './components/unsaveButton';
import dropdownSettings from '../modules/dropdowns/dropdown-settings/dropdownSettings';
import tableHours from '../modules/table-hours/table-hours.js';
import tabpanelReworked from '../modules/tab-panels/tabpanel--reworked.js';

import greyScale from './components/grayscale';
import greyScaleInit from './components/init-grayscale';
import darkmodeScale from './components/dark-mode.js';
import headerMegamenuFocusTrap from '../modules/headers/header__megamenu-extended/focus-trap.js';
//import tabPanelPreload from '../modules/tab-panel/tabpanel--preload.js';

window.Alpine = Alpine;

Alpine.plugin(collapse);
Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.data('collapseBox', collapseBox);
Alpine.data('card__comment', card__comment);
Alpine.data('headerTagged', headerTagged);
Alpine.data('headerLeftAligned', headerLeftAligned);
//Alpine.data('tabpanel', tabpanel);
Alpine.start();

document.addEventListener('DOMContentLoaded', function () {
	headerMegamenuExtended();
  headerMegamenuTabOrderMobile();
  headerMegamenuFocusTrap();
	leftAlignedMegamenu()
	doubleLogoMenu();
	login();
	headerIntranet();
	heroMultiBox();
	footers();
	// watermark();
  watermark('.footer');
	pageLoader();
	smoothScroll();
	blockMediaDownload();
	objectFitImages();
	subscriptionModal();
	pagination();
	carouselCards();
  swiper();
	articleAnchorMenu();
	accordion();
	accordionMobile();
  accordionDesktopDown();
	infoboxLinksIcon();
	threeColumnCarousel();
	progress();
	video();
	videoDreambroker();
	videoYoutube();
	videoHTML();
	BorgerDk();
	SBSYS();
	tooltips();
	intextArrowLink();
	serviceMenu();
  serviceMenuShareByEmail();
	dataTabel();
	dataTabelScrollable();
	formElements();
	sideMenu();
	instagramFeed();
	counterAnimation();
	modalPopup();
	tabListScrollable();
	videoBackground();
	cludoSearch();
	imageGallery();
	chat();
	onShowMore();
  onShowMoreCard();
	header();
	// cardHoursStatus();
	kultunaut();
	kultunautPopularEvent();
	moveNavItems();
	parallax();
	dropdownMdDown();
	bannerHours();
	breadcrumb();
	dropdownSettings();
	unsaveButton();
	autosuggestions();
	tableHours();
	//tabPanelPreload();
	tabpanelReworked();

	greyScale();
	greyScaleInit();
	darkmodeScale();
	cssVars({
		rootElement: document, // default
	});
});
